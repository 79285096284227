import React from 'react'
import HomeSearchByBrand from '../pages/search/HomeSearchByBrand'
import SearchByModle from '../search/SearchByModle'
export default function HomepageSearch() {
  return (
    <>
      <div className="car-search">
                            <h4> Find The Car</h4>
                        
                        <SearchByModle/>
            
                          </div>
    
    </>
  )
}
