import React from 'react'
import './TestBrand.css'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SearchByBrand from '../../search/SearchByBrand'
import Search from '../search/Search';
import Allcar from '../allcar/Allcar';
import SearchByModle from '../../search/SearchByModle';
export default function TestBrand() {
    const [brand, setBtand] = useState([]);
    const [id, setId] = useState()
 

    useEffect(()=>{
        let url =(`${process.env.REACT_APP_CAR_BRAND_API}`)
        axios.get(url).then((res)=>{
            setBtand(res.data)
        }).catch(err=>{
            console.log(err)
            
        })
    },[])


        const changeId =(id)=>{
            setId(id)
        }

    
  return (

    <>
    <Header/>
<div className="search-banner">
      
      <div className="top-banner">
         <div className="container">
         <span className='search-banner-title'> All Cars </span>
         </div>
      </div>
 </div>

 <div className="container">
   <div className="row">
       {/* all Category Lists */}
     <div className="col-md-3">
         <div className="car-search-box">
           <div className="searchbox">
           <h3> Search Cars</h3>
           <form>
                <div class="mb-3">
                <SearchByBrand/>
                <SearchByModle/>
               </div>
           </form>
           </div>
       
         </div>
        <div className="cars-all-brands">

            <div class="accordion" id="accordionExample">

            <div class="accordion-item"> 
            <h2 class="accordion-header" id="headingOne">
                {
                    brand?.map((brand)=>{
                        return(
                            <>
                            {
                                (brand.id===4)?(
                                    <>
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span className='main-brand' onClick={()=>changeId(4)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                        </button>
                                    </>
                                ):
                                <>
                               
                                </>
                            }
                            
                            </>
                        )
                    })
                }
            
            </h2>
                
            
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                
                <div class="accordion-body">
                {
                    brand?.map((brand)=>{
                        return(
                            <>
                                    {
                                        (brand.parent===4)?(
                                            <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                            </>
                                        ):
                                        (
                                            <>
                                            </>
                                        )
                                    }
                            </>
                        )
                    })
                }
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===13)?(
                                        <>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <span className='main-brand' onClick={()=>changeId(13)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>
                                    )
                                }
                                </>
                            )
                        })
                    }
            
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===13)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (<>
                                    </>)
                                    }
                                
                                </>
                            )
                        })
                    }
                
                
                </div>
                </div>
            </div>


            <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===9)?(
                                        <>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <span className='main-brand' onClick={()=>changeId(9)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                        </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                
                                </>
                            )
                        })
                    }
                
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===9)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                    {
                                        (brand.id===25)?(
                                            <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <span className='main-brand' onClick={()=>changeId(25)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                            </>
                                        ):
                                        (<></>)
                                    }
                                </>
                            )
                        })
                    }
                
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===25)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===21)?(
                                        <>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <span className='main-brand' onClick={()=>changeId(21)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                
                                </>
                            )
                        })
                    }
            
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===21)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===16)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            <span className='main-brand' onClick={()=>changeId(16)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===16)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===7)?(
                                        <>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        <span className='main-brand' onClick={()=>changeId(7)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                        </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                
                                </>
                            )
                        
                        })
                    }
            
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===7)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingEight">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===14)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            <span className='main-brand' onClick={()=>changeId(14)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===14)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingNine">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===26)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                <span className='main-brand' onClick={()=>changeId(26)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                
                                </>
                            )
                        })
                    }
            
                </h2>
                <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===26)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTen">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===12)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                <span className='main-brand'onClick={()=>changeId(12)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===12)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading11">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===18)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                            <span className='main-brand'onClick={()=>changeId(18)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }
            
                </h2>
                <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===18)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>
            
            <div class="accordion-item">
                <h2 class="accordion-header" id="heading12">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===11)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                                <span className='main-brand'onClick={()=>changeId(11)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===11)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading13">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===15)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                            <span className='main-brand'onClick={()=>changeId(15)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===15)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading14">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                    {
                                        (brand.id===17)?(
                                            <>
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                                    <span className='main-brand'onClick={()=>changeId(17)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                    </button>
                                            </>
                                        ):(
                                            <></>
                                        )
                                    }
                                </>
                            )
                        })
                    }
            
                </h2>
                <div id="collapse14" class="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===17)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading15">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                    {
                                        (brand.id===27)?(
                                            <>
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                                    <span className='main-brand'onClick={()=>changeId(27)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                    </button>
                                            </>
                                        ):
                                        (<></>)
                                    }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapse15" class="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===27)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading16">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                    {
                                        (brand.id===19)?(
                                            <>
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="false" aria-controls="collapse16">
                                                <span className='main-brand'onClick={()=>changeId(19)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                </button>
                                            </>
                                        ):
                                        (<> </>)
                                    }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapse16" class="accordion-collapse collapse" aria-labelledby="heading16" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===19)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading17">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===8)?(
                                        <>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="false" aria-controls="collapse17">
                                        <span className='main-brand'onClick={()=>changeId(8)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                        </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }
            
                </h2>
                <div id="collapse17" class="accordion-collapse collapse" aria-labelledby="heading17" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===8)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading18">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                    {
                                        (brand.id===23)?(
                                            <>
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="false" aria-controls="collapse18">
                                                <span className='main-brand'onClick={()=>changeId(23)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                </button>
                                            </>
                                        ):
                                        (<></>)
                                    }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapse18" class="accordion-collapse collapse" aria-labelledby="heading18" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===23)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading19">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                    {
                                        (brand.id===24)?(
                                            <>
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="false" aria-controls="collapse19">
                                                <span className='main-brand'onClick={()=>changeId(24)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                </button>
                                            </>
                                        ):
                                        (<></>)
                                    }
                                </>
                            )
                        })
                    }

                </h2>
                <div id="collapse19" class="accordion-collapse collapse" aria-labelledby="heading19" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===24)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading20">
                    
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===10)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="false" aria-controls="collapse20">
                                            <span className='main-brand' onClick={()=>changeId(10)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }
                </h2>
                <div id="collapse20" class="accordion-collapse collapse" aria-labelledby="heading20" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===10)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading21">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===28)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="false" aria-controls="collapse21">
                                            <span className='main-brand'onClick={()=>changeId(28)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):(
                                        <></>
                                    )
                                }
                                </>
                            )
                        })
                    }
                </h2>
                <div id="collapse21" class="accordion-collapse collapse" aria-labelledby="heading21" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===28)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading22">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                    {
                                        (brand.id===20)?(
                                            <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="false" aria-controls="collapse22">
                                                <span className='main-brand'onClick={()=>changeId(20)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                                </button>
                                            </>
                                        ):
                                        (<></>)
                                    }
                                </>
                            )
                        })
                    }
                </h2>
                <div id="collapse22" class="accordion-collapse collapse" aria-labelledby="heading22" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===20)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading23">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===22)?(
                                        <>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="false" aria-controls="collapse23">
                                            <span className='main-brand'onClick={()=>changeId(22)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }
                </h2>
                <div id="collapse23" class="accordion-collapse collapse" aria-labelledby="heading23" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===22)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading24">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===5)?(
                                        <>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                                            <span className='main-brand'onClick={()=>changeId(5)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                            </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }
            
                </h2>
                <div id="collapse24" class="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===5)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name'> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>

            <div class="accordion-item">
                <h2 class="accordion-header" id="heading25">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.id===6)?(
                                        <>
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
                                            <span className='main-brand'onClick={()=>changeId(6)}>{brand.name}  </span> <span className='totalcar'> ({brand.count} )</span> 
                                        </button>
                                        </>
                                    ):
                                    (<></>)
                                }
                                </>
                            )
                        })
                    }
                </h2>
                <div id="collapse25" class="accordion-collapse collapse" aria-labelledby="heading25" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    {
                        brand?.map((brand)=>{
                            return(
                                <>
                                {
                                    (brand.parent===6)?(
                                        <>
                                            <ul>
                                                <li> <span onClick={()=>changeId(`${brand.id}`)} className='brand-name' onClick={()=>changeId(`${brand.id}`)}> {brand.name} </span> <span className='brand-count'> {brand.count}</span> </li>
                                            </ul>
                                        </>
                                    ):
                                    (
                                        <>
                                        </>
                                    )
                                }
                                
                                </>
                            )
                        })
                    }
                
                </div>
                </div>
            </div>
            </div>




        </div>
     </div>
 
    {/* Data Display part */}
    <div className="col-md-9">
        <Search id={id}/>
        
        <Allcar/>
   
            
    </div>

  </div>
</div>
<Footer/>
    </>
  )
}
