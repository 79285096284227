import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './Contact.css'
import { Link } from 'react-router-dom'
import WhatsApp from '../../components/WhatsApp'
import {GeoAltFill, 
    Telephone, 
    Envelope, 
    Facebook, 
    Tiktok, 
    Linkedin, 
    Twitter,
    PersonFill,
    PatchCheckFill, 
    PhoneFill,
    PatchExclamation,
    Globe
 
} from 'react-bootstrap-icons'
export default function Contact() {
    const [contact, setContact] = useState([])

    useEffect(()=>{
        let url=(`${process.env.REACT_APP_CONTACT_US_API}`)
        axios.get(url).then((res)=>{
            setContact(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])
  return (
    <>
    <Header/>
    {
        contact?.map((contact)=>{
            return(
                <>
                    <div key={contact.id} className="contact-banner">
                            <div className="card c-banner-img"> 
                                <div className="card-img-ovarlay contact-title">
                                    <h3>{contact.title.rendered}</h3>
                                </div>
                            </div>
                        </div>

                        <div className="contact">
                            <div className="row">
                               <div className="col-md-6">
                               <div className="contact-1">
                                <div className="contact-content" dangerouslySetInnerHTML={{__html: contact.content.rendered}}/>
                               
                              
                                    <ul>
                                        <li> 
                                            <span className='contact-icon'> <PersonFill/> </span> 
                                            <span className='contact-text'> {contact.acf.contact_person} </span>
                                        </li>
                                        <li> 
                                            <span className='contact-icon'> <PhoneFill/> </span> 
                                            <span className='contact-text'> {contact.acf.phone} </span>
                                        
                                        </li>
                                        <li> 
                                            <span className='contact-icon'> <Telephone/> </span> 
                                        
                                            <span className='contact-text'> {contact.acf.phone_2} </span>
                                        </li>

                                        <li> 
                                            <span className='contact-icon'> <Envelope/> </span> 
                                            <span className='contact-text'> {contact.acf.email} </span>
                                           
                                        </li>

                                        <li> 
                                            <span className='contact-icon'> <Envelope/> </span> 
                                            <span className='contact-text'> {contact.acf.email_2} </span>
                                           
                                        </li>
                                        <li> 
                                            <span className='contact-icon'> <GeoAltFill/> </span> 
                                            <span className='contact-text'> {contact.acf.address} </span>
                                        </li>
                                        
                                        <li> 
                                            <span className='contact-icon'> <PatchCheckFill/> </span> 
                                            <span className='contact-text'> {contact.acf.registration} </span>
                                           
                                        </li>
                                        <li> 
                                            <span className='contact-icon'> <PatchExclamation/> </span> 
                                            <span className='contact-text'> {contact.acf.vat} </span>
                                        </li>
                                        <li> 
                                            <span className='contact-icon'> <Globe/> </span> 
                                            <Link to={contact.acf.website}>
                                            <span className='contact-text'> {contact.acf.website} </span>
                                            </Link>
                                        </li>
                                    </ul>
                                <WhatsApp/>
                                </div>
                               </div>
                               
                                <div className="col-md-6">
                                <div className="contact-2">
                                    <div className="contact-social">
                                        <ul>
                                            <li>
                                                <Link to={contact.acf.facebook} target='_blank'>
                                                <span className='contact-social' > <Facebook/> </span> 
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={contact.acf.tiktok} target='_blank'>
                                                <span className='contact-social'> <Tiktok/> </span> 
                                                </Link>
                                             
                                            </li>
                                            <li>
                                                <Link to={contact.acf.linkend} target='_blank'>
                                                <span className='contact-social'> <Linkedin/> </span> 
                                                </Link>
                                               
                                            </li>
                                            <li>
                                                 <Link to={contact.acf.twitter} target='_blank'>
                                                <span className='contact-social'> <Twitter/> </span> 
                                                </Link>
                                               
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                </div>
                             
                            </div>
                          
                            
                            <div className="container-fluid">
                                  <div className="map">
                                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14128.576527097464!2d85.3579408!3d27.7128358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1bdcc2ca48bb%3A0xf0d5d82a5fe41885!2sLions%20Automobiles%20and%20Workshop%20Pvt%20Ltd!5e0!3m2!1sen!2snp!4v1696928905270!5m2!1sen!2snp" width="3000" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                  </div>
                              </div>
                                </div>
                      
                </>
            )
        })
    }
 
    <Footer/>
    </>
  )
}
