import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useParams } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import EmailInquery from '../../components/EmailInquery'
import Recomended from '../../components/Recomended'
import {Link} from 'react-router-dom'



import {
    Whatsapp,
   PatchCheck ,CashCoin, CheckSquareFill , XCircleFill, FuelPump, CalendarDate, CheckCircleFill} from 'react-bootstrap-icons'
import axios from 'axios'
import './Single.css'

export default function SinglePage() {
    const {slug} = useParams();
    const[details, setDetails]=useState([])
        
    useEffect(()=>{
        let url = (`${process.env.REACT_APP_SINGLEDETAILS_API}/posts?slug=${slug}`)
        axios.get(url).then((res)=>{
            setDetails(res.data)
        }).catch(err=>{
            console.log('Error', err.message)
        })
    },[])

    var settings = {
        dots: true,
        infinite: true,
        // autoplay: true,
        speed: 300,
        slidesToShow: 1,
        arrows: true,
        adaptiveHeight: true
        };


  return (
   <>
    <Header/>
    
    {
        details?.map((details)=>{
            return(
                <>
                                 <div className="single-banner card">
                                        
                                        <img src={details.fimg_url} alt={'details.title.rendered'} className='img-fluid' />
                                <div className="single-banner-title card-img-overlay">
                                    <h1> {details.title.rendered}</h1>
                                
                                </div>
                                </div>
                            <div className="details-page">

                            
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="details-content">
                                                <Slider {...settings}>
                                                    <div className="slider-control">
                                                    <img src={details.fimg_url} alt={'details.title.rendered'} className='img-fluid' />
                                                    </div>

                                                    <div className="slider-control">
                                                    <img src={details.acf.gallery_image_1} alt={'details.title.rendered'} className='img-fluid' />
                                                    </div>
                                                    <div className="slider-control">
                                                    <img src={details.acf.gallery_image_2} alt={'details.title.rendered'} className='img-fluid' />
                                                    </div>
                                                    <div className="slider-control">
                                                    <img src={details.acf.gallery_image_3} alt={'details.title.rendered'} className='img-fluid' />
                                                    </div>
                                                </Slider>
                                            
                                            </div>
                                            <div className="features">
                                        <h4> Car Specification</h4>
                                            <div className="row">
                                          
                                            
                                            <div className="car-features">
                                             
                                                <ul>
                                                    <li> <spam className='details-title'> Brand:  </spam> <span className='car-details'> {details.acf.car_brand} </span>   </li>
                                                    <li>  <spam className='details-title'>  Model:  </spam> <span className='car-details'> {details.acf.car_modle} </span>   </li>
                                                    <li> <spam className='details-title'> Body Style:  </spam> <span className='car-details'> {details.acf.body_style} </span>   </li>
                                                    <li> <spam className='details-title'>  Engine:  </spam> <span className='car-details'> {details.acf.engine} </span>   </li>
                                                    <li> <spam className='details-title'> Doors:  </spam> <span className='car-details'> {details.acf.doors} </span>   </li>
                                                    <li> <spam className='details-title'> Passengers:  </spam> <span className='car-details'> {details.acf.passengers} </span>   </li>
                                                    <li> <spam className='details-title'> Fule Mileage:  </spam> <span className='car-details'> {details.acf.fuel_mileage} </span>   </li>
                                                    <li> <spam className='details-title'> fule Type:  </spam> <span className='car-details'> {details.acf.fuel_type} </span>   </li>
                                                    <li> <spam className='details-title'> Condition:  </spam> <span className='car-details'> {details.acf.condition} </span>   </li>
                                                    <li> <spam className='details-title'> Warenty:  </spam> <span className='car-details'> {details.acf.waeeanty} </span>   </li>
                                                    
                                                    
                                                </ul>
                                            </div>        
                                      
                                               

                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="short-info">
                                            <div className="details-t">
                                          
                                                    <div className="short-details">
                                                            <p>
                                                                <span className='top-icon'> <CalendarDate/> </span>  
                                                                <span className='top-info'> Modal: {details.acf.car_modle}</span>
                                                            </p>
                                                            <p>
                                                                <span className='top-icon'> <FuelPump/> </span>  
                                                                <span className='top-info'> Fuel Type:{details.acf.fuel_type}</span>
                                                            </p>
                                                            <p>
                                                                <span className='top-icon'> <PatchCheck/> </span>  
                                                                <span className='top-info'> Brand: {details.acf.car_modle}</span>
                                                            </p>
                                                
                                                    </div>
                                                    <p>
                                                        <span className='top-iconprice'> <CashCoin/> </span>  
                                                        <span className='top-infoprice'>Price: {details.acf.car_price}</span>
                                                    </p>
                                                    {
                                                        (details.acf.status_available===true)?(
                                                            <p>
                                                            <span className='abailable-icon'> <CheckCircleFill/> </span>
                                                            <span className='available'> Available </span>
                                                        </p>
                                                        ):
                                                        (
                                                            <p>
                                                            <span className='not-abailable-icon'> <XCircleFill/> </span>
                                                            <span className='not-available'> Not Available </span>
                                                        </p>
                                                        )
                                                    }
                                                
                                                
                                            
                                                </div>
                                            </div>
                                        <div className="features">
                                            
                                               
                                        <ul>
                                        {
                                                details?.acf?.car_features?.map((details,index)=>{
                                                    return(
                                                        <>
                                                        <li> 
                                                        <span className='checklist'> <CheckSquareFill/></span> 
                                                        <span className='featurelist'>  {details}</span>

                                                        </li>
                                                      
                                                        
                                                        </>
                                                    )
                                                })
                                            }

                                        </ul>
                                           
                                                 
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="car-features">
                         
                             
                                <div className="car-email-details">
                                    <div className="container">
                                        <div className="row">
                                        <div className="col-md-8">
                                            <div className="carinfodetails">
                                            <h4> Vehicle Description:</h4>
                                                        <span className="main-details" dangerouslySetInnerHTML={{__html: details.content.rendered}} />           
                                            </div>
                                            <div className="carinfodetails">
                                            <h4> Image of Vehicle:</h4>
                                                { (details.acf.gallery_image_1 !==0)?(
                                                    <>
                                                        <div className="gallery-image">
                                                            <img src={details.acf.gallery_image_1} alt={details.acf.image_description_1} className='img-fluid' />
                                                            <span className='gallery-info'> {details.acf.image_description_1}</span>
                                                        </div> 
                                                    </>
                                                    ):(
                                                        <></>
                                                    )
                                                }
                                                { (details.acf.gallery_image_2!==0)?(
                                                     <>
                                                        <div className="gallery-image">
                                                            <img src={details.acf.gallery_image_2} alt={details.acf.image_description_2} className='img-fluid' />
                                                            <span className='gallery-info'> {details.acf.image_description_2}</span>
                                                        </div>    
                                                        </>
                                                        ):(
                                                            <></>
                                                        )
                                                    }

                                                { (details.acf.gallery_image_3!==0)?(
                                                     <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_3} alt={details.acf.image_description_3} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_3}</span>
                                                </div> 
                                                </>
                                                ):(
                                                    <></>
                                                 )
                                                }

                                                { (details.acf.gallery_image_4!==0)?(
                                                     <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_4} alt={details.acf.image_description_4} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_4}</span>
                                                </div>  
                                                </>
                                                ):(
                                                    <></>
                                                 )
                                                }
                                                { (details.acf.gallery_image_5!==0)?(
                                                     <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_5} alt={details.acf.image_description_5} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_5}</span>
                                                </div>  
                                                </>
                                                ):(
                                                    <></>
                                                 )
                                                }  
                                                { (details.acf.gallery_image_6!==0)?(
                                                <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_6} alt={details.acf.image_description_6} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_6}</span>
                                                </div>
                                                </>
                                                ):(
                                                    <></>
                                                 )
                                                }
                                                 { (details.acf.gallery_image_7!==0)?(
                                                    <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_7} alt={details.acf.image_description_7} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_7}</span>
                                                </div>
                                                   </>
                                                   ):(
                                                       <></>
                                                    )
                                                   }  
                                                { (details.acf.gallery_image_8!==0)?(
                                                    <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_8} alt={details.acf.image_description_8} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_8}</span>
                                                </div>
                                                </>
                                                   ):(
                                                       <></>
                                                    )
                                                   }  

                                                { (details.acf.gallery_image_9!==0)?(
                                                <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_9} alt={details.acf.image_description_9} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_9}</span>
                                                </div>
                                                </>
                                                   ):(
                                                       <></>
                                                    )
                                                   }

                                                { (details.acf.gallery_image_10!==0)?(
                                                <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_10} alt={details.acf.image_description_10} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_10}</span>
                                                </div>
                                                </>
                                                   ):(
                                                       <></>
                                                    )
                                                   }
                                                { (details.acf.gallery_image_11!==0)?(
                                                <>
                                                <div className="gallery-image">
                                                    <img src={details.acf.gallery_image_11} alt={details.acf.image_description_11} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_11}</span>
                                                </div>
                                                </>
                                                   ):(
                                                       <></>
                                                    )
                                                   }
                                                   { (details.acf.gallery_image_12!==0)?(
                                                <>
                                                <div className="gallery-image">
                                                    <img src={details.gallery_image_12}  alt={details.acf.image_description_12} className='img-fluid' />
                                                    <span className='gallery-info'> {details.acf.image_description_12}</span>
                                                </div>
                                                 </>
                                                 ):(
                                                     <></>
                                                  )
                                                 }     
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            

                                            <div className="message">
                                                <h4> QUICK ENQUIRY</h4>
                                                <Link to={`https://wa.me/9779851038976?text=https://lionsautomobiles.com.np/search/${details.slug}`}>   
                                                    <div className="whatsapp">
                                                        <span className='whatsappicon'> <Whatsapp/> </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <EmailInquery/>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                     
          
                                </div>
                            
                            </div>
                          
               <Recomended/>
                </>
            )
        })
    }
     
    <Footer/>
    
    </>
  )
}
