import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './Logo'
export default function Header() {
  return (
    <> 
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand logo" to={'/'}>
            <div className="logoc">
              <div className="logo-img">
              <Logo/> 
              </div>
              <div className="logo-img">
              <span className='company-name'> Lions AutoMobiles  </span> <br/>
              <span className='company-name1' >  and Workshop Pvt. Ltd.</span>
                </div>
            </div>
           
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
             
           
              <li className="nav-item">
                <Link className="nav-link" to="/search"> All Car </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services"> Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/aboutus"> About Us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact Us </Link>
              </li>
   
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
