import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {CpuFill, 
  CalendarDate, 
  CashStack, 
  FuelPump, 
  Motherboard, 
  CarFrontFill, 
  PatchCheckFill
} from 'react-bootstrap-icons'

export default function Allcar() {
    const [totalcar, setTotalcar] = useState([])

    useEffect(()=>{
        let url =(`${process.env.REACT_APP_ALL_POST_API}`)
        axios.get(url).then((res)=>{
            setTotalcar(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])

  return (
    <>
    {
        totalcar?.map((totalcar)=>{
            return(
                <>
                <div className="car-list">
                    <div key={totalcar.id} className="row result">
                        <div className="col-md-5">
                          <div className="search-box-image">
                              <img src={totalcar.fimg_url} alt="" className='img-fluid' />
                          </div>
                        
                        </div>
                        <div className="col-md-7">
                          <div className="search-details">
                          <Link to={`/search/${totalcar.slug}`}>  <h3> {totalcar.title.rendered} </h3> </Link>
                            <p> <span className='search-price-icon'> <CashStack/> </span> <span className='search-price'> Rs: {totalcar.acf.car_price} </span>  </p>
                          
                          </div>
                          <div className="shear-info">
                            <ul>
                              <li> <span className='search-info-icon'> <CpuFill/> </span> 
                              <span className='search-info-text'> Engine: {totalcar.acf.engine} </span> </li>
                              <li> <span className='search-info-icon'> <CalendarDate/> </span> 
                              <span className='search-info-text'> Model: {totalcar.acf.car_modle} </span> </li>
                              <li> <span className='search-info-icon'> <FuelPump/> </span> 
                              <span className='search-info-text'>Fuel: {totalcar.acf.fuel_type} </span> </li>
                            
                            </ul>
                            
                            
                          
                          </div>
                          <div className="shear-info">
                            <ul>
                              <li> <span className='search-info-icon'> <CarFrontFill/> </span> 
                              <span className='search-info-text'>Car Type: {totalcar.acf.body_style} </span> </li>
                              <li> <span className='search-info-icon'> <PatchCheckFill/> 
                              </span> <span className='search-info-text'>Warranty {totalcar.acf.waeeanty} </span> </li>
                              <li> <span className='search-info-icon'> <Motherboard/> </span> 
                              <span className='search-info-text'> Condition: {totalcar.acf.condition} </span> </li>
          
                            </ul>
                            
                            
                          
                          </div>
                        </div>
                    </div>
                </div>
                </>
            )
        })
    }
              
    </>
  )
}
