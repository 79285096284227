import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './SearchPage.css'

export default function SearchByModle() {

    const [search, setSearch] = useState([])

    const [brandresult, setBrandResult] = useState(search)
    
    useEffect(()=>{
        let url = (`${process.env.REACT_APP_MODEL_SEARCH_API}`)
        axios.get(url).then((res)=>{
            setSearch(res.data)
        }).catch(err=>{
            console.log(err)
        })
    },[])

    const ModelFilter =(e)=>{
        
        if(e.target.value==""){
            setBrandResult([])
        }else{
            setBrandResult(search.filter(f=> f.acf.car_modle.toLowerCase().includes(e.target.value))) 
        }
    }

  
  return (
    <>
    <div className="serchbymodel">
        <input 
        type="text"
        placeholder='Enter Car Model'
        className='searchbymodel'
        onChange={ModelFilter}/>

    </div>
    <div className="modelResult">
        <ul className='modellist'>
            {
                brandresult?.map((brandresult)=>{
                    return(
                        <>
                        <Link to={`/search/${brandresult.slug}`}>
                            <li>{brandresult.acf.car_modle}</li>
                        </Link>
                        
                        </>
                    )
                })
            }
        </ul>
    </div>
    </>
  )
}
