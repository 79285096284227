import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'


export default function Logo() {
    const [logo, setLogo] =useState([])

    useEffect(()=>{
        let url=(`${process.env.REACT_APP_LOGO_API}`)
        axios.get(url).then((res)=>{
            setLogo(res.data)
        })

    },[])
  return (
    <>
    {
        logo?.map((logo)=>{
            return(
                <>
                       <span key={logo.id}>
                            <img src={logo.featured_image.medium} alt="Lions Automobile and Workshop Logo" className='img-fluid' />
                        </span>
                </>
            )
        })
    }
 
    </>
  )
}
